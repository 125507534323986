import { DefaultUrlSerializer, PRIMARY_OUTLET, Router } from '@angular/router';
import { compact, flatten, get, reduce } from 'lodash';

import { DateUtils } from '@app/core/misc/date-utils';
import moment from 'moment';

export namespace Utils {
  /**
   * строит строку варианта произношения числительного
   * @see http://docs.translatehouse.org/projects/localization-guide/en/latest/l10n/pluralforms.html?id=l10n/pluralforms
   * @param number количество элементов
   * @param plurals это массив слов для варианта произношения [отзыв, отзыва, отзывов] например для чисел 1 2 5
   */
  export const pluralCase = (number: number, plurals: Array<string>): string => {
    return plurals[
      number % 10 === 1 && number % 100 !== 11
        ? 0
        : number % 10 >= 2 && number % 10 <= 4 && (number % 100 < 10 || number % 100 >= 20)
        ? 1
        : 2
    ];
  };

  /**
   * генерирует случайную строку символов
   * @param ln размерность требуемой строки
   */
  export const randomString = (ln: number = 6): string =>
    Math.round(36 ** (ln + 1) - Math.random() * 36 ** ln)
      .toString(36)
      .slice(1);

  /**
   * округление до сотых
   * @param val число
   */
  export const roundingToHundredths = (val: number): number => Math.round(val * 100) / 100;

  /**
   * замена пустых значений в объекте на null
   * @param data объект данных
   */
  export const emptyToNull = (data: any = {}): any => {
    const strData: string = JSON.stringify(data);
    return JSON.parse(strData.replace(/:\"\"/g, ':null'));
  };

  /**
   * делает заглавным первый символ строки
   * @param str строка
   * @param everyWord признак делать прописными каждое слово в строке
   */
  export const capitalizeFirst = (str: string, everyWord: boolean = false): string => {
    if (!everyWord) {
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }

    const splitted = str.split(' ');
    return splitted
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  /**
   * увеличивает строку или число слева на количество нулей до size
   * @param num обрабатываемое число или строка
   * @param size размер строки
   */
  export const pad = (num: number | string, size: number = 2): string => {
    let str = `${num}`;
    while (str.length < size) {
      str = `0${str}`;
    }
    return str;
  };

  /**
   * сериализатор модели в строку параметров url
   * @param obj модель данных
   * @param isDateToDate параметр, указывающий, необходимо ли создавать точную текущую дату
   */
  export const serialize = (obj: { [key: string]: any }, isDateToDate = false): string => {
    const str = compact(
      Object.keys(obj).map(propName => {
        // для дат преобразование в строке в iso формат
        const val =
          obj[propName] instanceof Date
            ? isDateToDate
              ? moment(obj[propName] as Date).format('YYYY-MM-DDTHH:mm:ss')
              : (obj[propName] as Date).toISOString()
            : obj[propName];
        return (typeof val !== 'boolean' && !val) || val.length === 0 // если не булев и нет значения или пустой массив
          ? null
          : `${encodeURIComponent(propName)}=${encodeURIComponent(val)}`;
      })
    );
    return str.length === 0 ? '' : `?${str.join('&')}`;
  };

  /**
   * сериализатор модели в строку параметров url
   * @param arr масив данных
   * @param name название перечисляемых свойств
   */
  export const arrSerialize = (name: string, arr: string[] = []): string => {
    return arr
      .map(item => {
        return `&${name}=${item}`;
      })
      .join('');
  };

  /**
   * Сериализауция модели(включая массивы) в строку параметров url
   * @param obj модель данных
   */
  export const getGroupRequestsSerializer = (obj: { [key: string]: any }): string => {
    const str = compact(
      Object.keys(obj).map(propName => {
        // для дат преобразование в строке в iso формат
        let val =
          obj[propName] instanceof Date
            ? // передаем дату устройства, игнорируя часовой пояс
              DateUtils.dateWithoutTimeToUTC(obj[propName] as Date)
            : obj[propName];
        return (typeof val !== 'boolean' && !val) || val.length === 0 // если не булев и нет значения или пустой массив
          ? null
          : Array.isArray(val) //если массив, разложим его по параметрам в query string
          ? getGroupRequestsSerializerArray(propName, val)
          : `${encodeURIComponent(propName)}=${encodeURIComponent(val)}`;
      })
    );
    return str.length === 0 ? '' : `?${str.join('&')}`;
  };

  /**
   * Cериализатор массива в строку параметров url.
   * @param propName название параметра query string
   * @param array массив
   * @return возвращает строку вида 'propName=array[0]&propName=array[1]&propName=array[n]'
   */
  export const getGroupRequestsSerializerArray = (propName: string, array: unknown[]): string =>
    array
      .map((obj: unknown) =>
        Utils.getGroupRequestsSerializer({
          [propName]: obj,
        })
      )
      .join('')
      .replace(/\?+/g, '&')
      .substr(1);

  /**
   * функция декартова перемножения
   * @param arr массив данных
   */
  export const cartesian = (arr: Array<any>): Array<any> =>
    reduce(arr, (a, b) => flatten(a.map(x => (b as []).map(y => x.concat([y])))), [[]]);

  /**
   * убирает ведущие нули в строке
   * @param str строка для проверки
   */
  export const clearFirstNull = (str: string = ''): string => {
    const charNull = str.charAt(0) === '0' && str.length > 1;
    return charNull ? clearFirstNull(str.substring(1)) : str;
  };

  /**
   * разбивает массив на n массивов
   * @param arr исходный массив любых значений
   * @param n размерность итогового массива
   */
  export const splitTo = (arr: Array<any>, n: number = 1): Array<Array<any>> => {
    const plen = Math.ceil(arr.length / n);

    return arr.reduce((prev: Array<Array<any>>, current: any, index: number) => {
      if (index % plen === 0) {
        prev.push([]);
      }
      prev[prev.length - 1].push(current);
      return prev;
    }, []);
  };

  export const calculatePercentage = (sum: number, perc: number): number => (sum * perc) / 100;

  /** Возвращаеть сумму чисел в аргументах, с некоторой точностью  */
  export const getTotalSum = (...args: number[]): number =>
    +(
      args.reduce(
        (accumulator, currentValue, index) => accumulator + (+currentValue || 0) * 100,
        0
      ) / 100
    );

  /** Вернуть НДС */
  export const getVAT = (fee: number): number => Math.round(fee * 0.2 * 100) / 100;

  /** Вернуть НДС */
  export const getCurrentVAT = (fee: number): number => +((+fee * 20) / 120).toFixed(2);

  /** нормализует номер телефона. Обрезает лишние символы и добавляет впереди +7
   * например 89174091827 превращает в +79174091827 */
  export const normalizePhoneNumber = (phoneStr: string): string => {
    // удаляем "+7", "+" и любые символы кроме чисел
    const phoneStrCleaned = `${phoneStr || ''}`.replace(/(\+7)|(\+)|\D+/g, '');
    if (phoneStrCleaned.length > 10) {
      // добавляем плюс семерку и обрезаем с начала(слева направо) до 10 символов номер телефона
      return `+7${phoneStrCleaned.substr(phoneStrCleaned.length - 10, phoneStrCleaned.length)}`;
    }
    return `+7${phoneStrCleaned}`;
  };

  /** извлекает число из объекта по ключу и если не получилось то null  */
  export const extractNumber = (obj: any, path: string): number | null => {
    const extractedNumber = Number(get(obj, path, null));
    if (Number.isNaN(extractedNumber) || typeof extractedNumber !== 'number') {
      return null;
    }
    return extractedNumber;
  };

  /**
   *  Заменяет в url переменные на данные
   * @param path путь с переменными
   * @param obj объект со значениями переменных
   * @returns собранная строка
   *
   * @example
   * Utils.replacePathTemplate('/foo/:bar', { bar: 'test' }) // '/foo/test'
   *
   */
  export const replacePathTemplate = (path: string, obj: any): string => {
    const dus = new DefaultUrlSerializer();
    const tree = dus.parse(path);
    const { segments } = tree.root.children[PRIMARY_OUTLET];
    segments.forEach(segment => {
      // eslint-disable-next-line no-param-reassign
      Object.keys(obj).forEach(objKey => {
        // ищем точное соответствие
        const regexp = new RegExp(`^:${objKey}$`, 'g');
        // eslint-disable-next-line no-param-reassign
        segment.path = segment.path.replace(regexp, obj[objKey]);
      });
    });
    return dus.serialize(tree);
  };

  /** Возвращает строку с корректным окончанием в зависимости от колличества записей (напр. 1 сотрудник, 2 сотрудника, 6 сотрудников). */
  export const getStringWithEndByNumber = (n: number, forms: string[]): string => {
    n = Math.abs(n) % 100;
    const n1 = n % 10;
    if (n > 10 && n < 20) return forms[2];
    if (n1 > 1 && n1 < 5) return forms[1];
    if (n1 === 1) return forms[0];
    return forms[2];
  };

  /** парсер URL для получения обьекта с queryParam
   *  для новой схемы авторизацыи, в момент иницыализацыи когда Routr еще не активирован
   */
  export const getQueryParamFromURL = (url: string = ''): any => {
    // queryParam сюда в фотмате ключ значение записываем все queryParam
    const queryParam: any = {};
    // отделяем адрес от параметров url?param
    const params = url.split('?')[1];
    // отделяем параметры друг от друга param&param
    if (params) {
      const paramArr = params.split('&');
      // итерируем параметры и записываем в формате ключ значение
      if (params.length > 1 && paramArr.length > 1) {
        paramArr.forEach(item => {
          const itemParam = item.split('=');
          queryParam[itemParam[0]] = itemParam[1];
        });
      }
    }
    return queryParam;
  };

  /** склеиваем данные для названия номера договора
   * название договора должно иметь сл вид
   * '№ 233/16-02/20 от «24» марта 2020 года'
   * */
  export const concatContractNumberValue = (
    contractTextName: string = '',
    contractDate: Date | null = null
  ): string => {
    const body = contractTextName;
    const dateText = `${DateUtils.dateToStringWithoutUTCForContractNumber(contractDate)}`;
    return !body && !dateText ? '' : `№ ${body} от ${dateText}`;
  };
}
