import { Injectable } from '@angular/core';
import { IAppSettings } from '@app/core/interfaces/app-settings.interface';

/**
 * сервис конфигурации приложения
 */
@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  /** имена полей в локалсторадже */
  public readonly localStorageNames = {
    /** имя поля токена авторизации в локалсторадже */
    accessToken: 'jwt_access_token',
    /** имя поля токена рефреша в локалсторадже */
    refreshToken: 'jwt_refresh_token',
    /** имя поля сохраненного аккаунта в локалсторадже */
    account: 'recent_account',
    /** имя поля выбранного языка в локалсторадже */
    language: 'lang',
  };

  /** конфиг приложения */
  public globalConfig: IAppSettings = {
    businessCardUrl: '',
    einvocingUrl: '',
    paymentLinkTemplate: '',
    piwikSiteId: 1,
    piwikScriptUrl: 'https://einv-test.esphere.ru/mtmo/piwik',
    loginUrl: '',
    checkUnpaymentOrdersInterval: 5 * 60 * 1000,
    checkReserveSegmentsInterval: 10 * 1000,
    checkAccountPaymentCreatedInterval: 10 * 1000,
    checkAccountPaymentPaidInterval: 20 * 1000,
    cashbackSize: 10,
  };

  /** для хранения относительного пути приложения */
  private _relativePathApp = '';

  /** получить url локации с поддоменом */
  public get relativePath(): string {
    return this._relativePathApp;
  }

  constructor() {
    this._relativePathApp = `${window.location.origin}${(window as any)['_bshref']}`;
  }
}
