import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '@env/environment';
/**
 * сервис доступа к api
 */
@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private _http: HttpClient) {}

  /**
   * скачивание файла
   * @param file блоб файла
   * @param mame имя файла
   */
  public static downloadFile(file: Blob, name: string): void {
    // для ie
    if ((window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(file, name);
    } else {
      // для всех остальных браузеров
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.download = name;
      const event = document.createEvent('MouseEvents');
      event.initMouseEvent(
        'click',
        false,
        false,
        window,
        0,
        0,
        0,
        0,
        0,
        false,
        false,
        false,
        false,
        0,
        null
      );
      link.dispatchEvent(event);
    }
  }

  /**
   * метод доступа к апи сервера
   * @param action экшн к апи
   * @param body данные запроса
   * @param method метод для обращения к серверу
   * @param responseType тип ответа
   * @param downloadFileName имя файла для сохранения результата
   * @param silent признак игнорирования ошибок при обращении к апи
   * @param downloadFile признак сохранения файла
   * @param apiLabel лейбл сервиса, по нему, через environment.ts,
   * определяеться URL сервиса в proxy.conf
   * если не передан подставляеться старое api v1
   */
  public request<T>(
    action: string = '',
    body?: any,
    method: 'patch' | 'post' | 'get' | 'put' | 'delete' = 'post',
    responseType: 'json' | 'arraybuffer' | 'text' = 'json',
    downloadFileName?: string,
    silent: boolean = false,
    downloadFile: boolean = false,
    apiLabel: 'version' | 'authUrl' | 'apiUrl' | 'api2Url' | 'api3Url' | 'apiTGateway' | 'avatarUrl' = 'apiUrl'
  ): Observable<T> {
    if (downloadFileName) {
      responseType = 'arraybuffer';
    }

    const opts: any = {
      responseType,
      observe: 'response',
      headers: new HttpHeaders({
        silent: silent ? '1' : '0',
        'Accept-Language': 'ru-RU',
      }),
    };

    // если есть body у delete
    if (method === 'delete' && body) {
      opts.body = body;
    }

    let url = environment[apiLabel] + action;

    let req;
    switch (method) {
      case 'get':
        req = this._http.get<T>(url, opts);
        break;

      case 'put':
        req = this._http.put<T>(url, body, opts);
        break;

      case 'delete':
        req = this._http.delete<T>(url, opts);
        break;

      case 'patch':
        req = this._http.patch<T>(url, body, opts);
        break;

      default:
        req = this._http.post<T>(url, body, opts);
        break;
    }

    // тип контента ответа
    let contentTypeResponse = '';
    return req.pipe(
      tap((response: any) => {
        contentTypeResponse = response.headers.get('Content-Type');

        /** downloadFileName - установить из заголовка ответа при неустановленном имени файла */
        if (responseType === 'arraybuffer' && !downloadFileName) {
          const contentDisposition = response.headers.get('Content-Disposition');
          downloadFileName = decodeURI(
            contentDisposition.match(/name\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi)[1]
          );

          let regexpNames = /name\*?=['"]?(?:UTF-\d['"]*)?([^;\r\n"']*)['"]?;?/gi;
          let index = 1; // default to the first capturing group
          let matches = [];
          let match;
          while ((match = regexpNames.exec(contentDisposition))) {
            matches.push(match[index]);
          }

          if (matches.length) downloadFileName = decodeURI(matches[matches.length - 1]);
        }
      }),
      map((response: any) => response.body),
      tap((response: any) => {
        // если запрашивали файл
        if (responseType === 'arraybuffer') {
          // tslint:disable-next-line: no-angle-bracket-type-assertion
          const blob = new Blob([<ArrayBuffer>response], { type: contentTypeResponse });
          // если происходит сохранение в файл - создаем ссылку и загружаем ресурс
          if (downloadFile) {
            ApiService.downloadFile(blob, downloadFileName || 'no-name');
          } else {
            // иначе открываем во вкладке без скачивания
            const fileURL = window.URL.createObjectURL(blob);
            window.open(fileURL, '_blank');
          }
        }
      })
    );
  }
}