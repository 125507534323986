export const environment = {
  production: true,
  version: 'version/',
  authUrl: 'v1/',
  apiUrl: 'v1/',
  api2Url: 'v2/',
  api3Url: 'v3/',
  apiTGateway: 'travel-gateway/',
  avatarUrl: 'pictures/',
  noEmulateAuth: false,
  auth: {
    issuer: 'https://sso-tst.esphere.ru/auth/oauth2/lkk_sfera',
    showDebugInformation: true,
    requireHttps: false,
  },
  info: {
    email: 'vk@vipservice.ru',
    phone: '8 800 101 51 51',
  },
};
