
import { Utils } from '@app/core/misc/utils';
import moment from 'moment';

/** DateUtils
 * инструменты для работы со временем
 * */

export namespace DateUtils {
  /** формат даты по умолчанию */
  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

  /**
   * преобразует строку в дату
   * @param date дата в виде строки или объекта даты
   */
  export const toDate = (date: Date | string): Date =>
    typeof date === 'string' ? new Date(date) : date;

  /**
   * обнуление времени в дате
   * @param date дата
   */
  export const clearTime = (date: Date = new Date()): Date => {
    date.setHours(0, 0, 0, 0);
    return date;
  };

  /**
   * добавление дней к дате
   * @param date дата
   * @param numberOfDays количество дней для добавления
   */
  export const addDaysToDate = (date: Date, numberOfDays: number = 0): Date =>
    moment(date).add(numberOfDays, 'days').toDate();

  /**
   * отнимает дни от даты
   * @param date дата
   * @param numberOfDays количество дней
   */
  export const subtractDaysToDate = (date: Date, numberOfDays: number = 0): Date =>
    moment(date).subtract(numberOfDays, 'days').toDate();

  /**
   * получение начала суток
   * @param date дата
   */
  export const getStartDay = (date: Date | string): Date => moment(date).startOf('day').toDate();

  /**
   * получение конца суток
   * @param date дата
   */
  export const getEndDay = (date: Date | string): Date => moment(date).endOf('day').toDate();

  /**
   * преобразует количество минут в строку вида 'HH:MM'
   * @param minutes минуты
   */
  export const minutesToTimeString = (minutes: number): string => {
    const h = Utils.pad(Math.floor(minutes / 60).toString());
    const m = Utils.pad((minutes % 60).toString());
    return `${h}:${m}`;
  };

  /**
   * !! deprecated !!
   * Данный механизм устарел, необходимо использовать datesToTravelTime
   * в дальнейшем datesToTravelTime небходимо переименовать согласно назначению
   *
   * определяет разницу во времени двух дат, результат вида 'hh:mm' или 'd.hh:mm'
   * @param date1 первая дата
   * @param date2 вторая дата
   * @param extractDays признак выделения дней из часов
   * @param format формат даты
   */
  export const differenceTime = (
    date1: Date | string,
    date2: Date | string,
    extractDays: boolean = false,
    format: string = DATE_FORMAT
  ): string => {
    const diff = moment(date1, format).diff(moment(date2, format), 'minutes');
    let days = 0;
    let hours = Math.floor(diff / 60);
    const minutes = diff % 60;
    if (extractDays && hours >= 24) {
      days = Math.floor(hours / 24);
      hours %= 24;
    }

    return extractDays && days > 0
      ? `${days}.${Utils.pad(hours)}:${Utils.pad(minutes)}`
      : `${Utils.pad(hours)}:${Utils.pad(minutes)}`;
  };

  /**
   * преобразует строку времени вида 'hh:mm' или 'd.hh:mm' в массив значений вида [d, h, m]
   * @param time строка времени
   */
  export const timeStringToArray = (time: string): Array<number> => {
    const splitByDays = time.split('.');
    let days = 0;
    let splitStr: Array<string>;
    if (splitByDays.length === 2) {
      days = parseInt(splitByDays[0], 10);
      splitStr = splitByDays[1].split(':');
    } else {
      splitStr = splitByDays[0].split(':');
    }
    const minutes = parseInt(splitStr[1], 10);
    const allHours = parseInt(splitStr[0], 10);
    const res = [days, 0, 0];

    if (allHours > 24) {
      days = Math.floor(allHours / 24);
      res[0] = days;

      const hours = allHours % 24;
      if (hours > 0) {
        res[1] = hours;
      }
    } else if (allHours > 0) {
      res[1] = allHours;
    }

    if (minutes > 0) {
      res[2] = minutes;
    }

    return res;
  };

  /**
   * перевод массива времени вида [d, h, m] в вид 'd.hh:mm'
   * @param arr массив времени вида [d, h, m]
   */
  export const timeArrayToString = (arr: Array<number>): string =>
    arr[0] > 0
      ? `${Utils.pad(arr[0])}.${Utils.pad(arr[1])}:${Utils.pad(arr[2])}`
      : `${Utils.pad(arr[1])}:${Utils.pad(arr[2])}`;

  /**
   * получаем строку даты независимую от часового пояса
   * @param date строка даты или дата
   */
  export const getDateStringIgnoreTimezone = (date: string | Date): string =>
    moment
      .utc(date)
      .add(moment.parseZone(date).utcOffset() / 60, 'hours')
      .format(DATE_FORMAT);

  /**
   * Перевод из строки в обьект даты игнорирую тайм зону
   * возвращает дату/время как локальную, игнорируя таймзону */
  export const getDateAsLocalTimezone = (dateStr: unknown): Date | null => {
    if (!dateStr) {
      return null;
    }
    const dateMoment = moment(moment.parseZone(dateStr).format('YYYY-MM-DDTHH:mm:ss'));
    return dateMoment.isValid() ? dateMoment.toDate() : null;
  };

  /** возвращает дату в формате ISO в таймзоне UTC
   * например 2020-08-11T20:55:28Z
   * */
  export const getDateStrAsUtcTimezone = (date: string | Date): string | null => {
    if (!date) {
      return null;
    }
    const dateMoment = moment(date, 'DD.MM.YYYY');
    return dateMoment.isValid() ? `${dateMoment.format('YYYY-MM-DDTHH:mm:ss')}Z` : null;
  };

  /** объеденят две даты (дата и время) в форматированную строку в UTC формате YYYY-MM-DDTHH:mmZ
   * @param date Date без учета времени
   * @param time Date без учета даты
   * @return дата в формате YYYY-MM-DDTHH:mmZ например 2021-03-04T01:02Z
   * */
  export const convertDateAndTimeToUtcFormatDate = (date: Date, time: Date): string | null => {
    if (date && time) {
      const dateMoment = moment(date);
      const timeTimeMoment = moment(time);
      return `${dateMoment.format('YYYY-MM-DD')}T${timeTimeMoment.format('HH:mm:ss')}Z`;
    }
    return null;
  };

  /** объеденят две даты (дата и время) в форматированную строку в формате YYYY-MM-DDTHH:mm
   * @param date Date без учета времени
   * @param time Date без учета даты
   * @return дата в формате YYYY-MM-DDTHH:mm например 2021-03-04T01:02
   * */
  export const convertDateAndTimeToFormatDate = (date: Date, time: Date): string | null => {
    if (date && time) {
      const dateMoment = moment(date);
      const timeTimeMoment = moment(time);
      return `${dateMoment.format('YYYY-MM-DD')}T${timeTimeMoment.format('HH:mm:ss')}`;
    }
    return null;
  };

  /**
   * дата в строку без времени, игнорируя часовой пояс
   * @param date дата
   */
  export const dateWithoutTime = (date: Date | string | number): string | null => {
    if (!date) return null;
    if (!(date instanceof Date)) date = new Date(date);

    return `${Utils.pad(date.getDate())}.${Utils.pad(date.getMonth() + 1)}.${date.getFullYear()}`;
  };

  /**
   * дата в строку без времени, игнорируя часовой пояс
   * @param date дата
   */
  export const dateWithoutTimeToUTC = (date: Date | string | number): string | null => {
    if (!date) return null;
    if (!(date instanceof Date)) date = new Date(date);

    return `${date.getFullYear()}-${Utils.pad(date.getMonth() + 1)}-${Utils.pad(
      date.getDate()
    )}T00:00:00.000Z`;
  };

  /**
   * дата в строку без времени, игнорируя часовой пояс
   * в формате «24» февраля 2022 года'
   * @param date дата
   */
  export const dateToStringWithoutUTCForContractNumber = (date: Date | string | number | null): string => {
    if (!date) return '';
    if (!(date instanceof Date)) date = new Date(date);

    return `«${date.getDate()}» ${
      moment(date).format('D MMMM').split(' ')[1]
    } ${date.getFullYear()} года`;
  };

  /**
   * определяет произошла ли смена дня от одной даты к другой
   * @param date1 первая дата
   * @param date2 вторая дата
   */
  export const detectNextDay = (date1: Date | string, date2: Date | string): boolean => {
    date1 = toDate(date1);
    date2 = toDate(date2);

    return date1.getDate() - date2.getDate() < 0 || date1.getMonth() - date2.getMonth() < 0;
  };

  /**
   * находит разницу двух дат в сутках
   * @param date1 дата начала периода
   * @param date2 дата конца периода
   * @return дни, целое число
   */
  export const differenceDays = (date1: Date | string, date2: Date | string): number => {
    const date1Moment = moment(date1);
    const date2Moment = moment(date2);
    return Math.abs(date2Moment.diff(date1Moment, 'days'));
  };

  export const dateIgnoreTime = (date: Date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    return new Date(year, month, day, 0, 0, 0);
  };

  /** переводит разницу между датами в формат d.HH.mm */
  export const datesToTravelTime = (date1: Date | string, date2: Date | string): string => {
    const a = moment(date1);
    const b = moment(date2);

    const days = a.diff(b, 'days');
    b.add(days, 'days');

    const hours = a.diff(b, 'hours');
    b.add(hours, 'hours');

    const minutes = a.diff(b, 'minutes');
    b.add(minutes, 'minutes');

    return `${days ? `${Math.abs(days)}.` : ''}${Math.abs(hours)}:${Math.abs(minutes)}`;
  };

  /** восстановление даты из строки, без учета смещения часового пояса
   * фиксит смещение даты
   * */
  export const setDateIgnoreTimezone = (val: Date | string): Date | null => {
    if (val instanceof Date) {
      return val;
    }
    if (!val) {
      return null;
    }

    // сплитим дату по +
    const splitted = (val as string).split('+');
    // если нет временной зоны, значит сервер вернул в формате iso, перед формированием даты удаляем концевой Z
    if (splitted.length === 1 && splitted[0].charAt(splitted[0].length - 1) === 'Z') {
      val = splitted[0].slice(0, -1);
    } else {
      val = splitted[0];
    }
    return new Date(val);
  };

  /** восстановление даты */
  export const setDate = (val: Date | string | number): Date | null => {
    if (val instanceof Date) {
      return val;
    }
    if (val === null) {
      return null;
    }
    return new Date(val);
  };

  /** установление времени в текущей дате для тайм пикера */
  export const setTimepickerDate = (hour: number = 0, minutes: number = 0): Date => {
    return moment().set('hours', hour).set('minute', minutes).set('seconds', 0).toDate();
  };

  /** получение минут и часов даты  */
  export const getTimeDate = (date: Date): { hours: number; minute: number } => {
    const timeData = {
      hours: moment(date).get('hours'),
      minute: moment(date).get('minute'),
    };
    return timeData;
  };

  /** приводит дату к нужному формату после датапикера  */
  export const getDateFromMomentValue = (dateValue: string | Date | null): Date | null => {
    if (!!dateValue) {
      return moment(dateValue, 'DD.MM.YYYY').isValid()
        ? moment(dateValue, 'DD.MM.YYYY').toDate()
        : null;
    } else {
      return null;
    }
  };
}
